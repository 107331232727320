import { render, staticRenderFns } from "./EditAgent.component.vue?vue&type=template&id=71aa0154&"
import script from "./EditAgent.component.vue?vue&type=script&lang=ts&"
export * from "./EditAgent.component.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/iampoker-new-ui/iampoker-new-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71aa0154')) {
      api.createRecord('71aa0154', component.options)
    } else {
      api.reload('71aa0154', component.options)
    }
    module.hot.accept("./EditAgent.component.vue?vue&type=template&id=71aa0154&", function () {
      api.rerender('71aa0154', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Tournament/EditTournament/EditAgent.component.vue"
export default component.exports