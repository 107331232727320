import { Component, Vue, Watch } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import { GET_TOURNAMENT_BY_ID, UPDATE_TOURNAMENT } from "../../../../api/tournament"
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'
import moment from 'moment';
import { TIER_NAME, BUYIN_OPTIONS, REWARD_PERCENTAGE_OPTIONS, PEOPLE_PERCENTAGE_OPTION } from '../../Constants/tournament'
import cronstrue from 'cronstrue/i18n';

import 'vue2-datepicker/index.css';

Vue.use(VModal, { componentName: 'modal' })
Vue.use(VueCookies)

import 'vue-select/dist/vue-select.css';

@Component({
    components: {
        vSelect,
        DatePicker
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        date(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        Currency(value: any) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})

default class Agent extends Vue {

    public displayShow = true

    private model: Model;

    constructor() {
        super();
        this.model = new Model();
    }

    public tierNameOptions: string[] = TIER_NAME
    public minuteScheduleOptions: any = []
    public hourScheduleOptions: any = []
    public dayOfMonthScheduleOptions: any = []
    public monthScheduleOptions: any = []
    public dayOfWeekScheduleOptions: any = []


    public buyinOptions: any[] = BUYIN_OPTIONS

    public allRewardOptions: any[] = []
    public rewardPercentageOptions: any[] = REWARD_PERCENTAGE_OPTIONS
    public peoplePercentageOptions: any[] = PEOPLE_PERCENTAGE_OPTION

    private tournamentId
    public tierName = "MONTE CARLO"
    public buyin = 1000
    public startStacksize = '0'
    
    public minimumOfPeople = 5
    public maximumOfPeople = 100
    public startTime = moment().local().startOf('day').toDate()
    
    
    // public end_time = moment().local().endOf('day').toDate()
    public minSchedule: any = '*'
    public hourSchedule: any = '*'
    public dayOfMonthSchedule: any = '*'
    public monthSchedule: any = '*'
    public dayOfWeekSchedule: any = '*'

    public durationOfSchedule = 0
    public registerLate = 0

    public allReward = 40
    public topRewards = [{ rewardPT: '0' }]
    public baseRewards = [{ playerPT: '0', rewardPT: '0' }]
    public activeAnte = true
    public blindLevels: any = []

    private generateSchedule(from: number, to: number): any[] {
        const schedule: any[] = [ { label: '*', value: '*'}]
        for (let i = from; i <= to; i++) {
            schedule.push({ label: i, value: i})
        }
        return schedule
    }


    private generateAllRewardOptions(): void{
        for(let i = 1; i <= 100; i++){
            this.allRewardOptions.push({ label: `${i}%`, value: i})
        }
    }

    private generateMonthSchedule(): any[] {
        return [
            { label: "*", value: "*"},
            { label:this.$t('JANUARY'), value: 1},
            { label:this.$t('FEBRUARY'), value: 2},
            { label:this.$t('MARCH'), value: 3},
            { label:this.$t('APIRL'), value: 4},
            { label:this.$t('MAY'), value: 5},
            { label:this.$t('JUNE'), value: 6},
            { label:this.$t('JULY'), value: 7},
            { label:this.$t('AUGUST'), value: 8},
            { label:this.$t('SEPTEMBER'), value: 9},
            { label:this.$t('OCTOBER'), value: 10},
            { label:this.$t('NOVEMBER'), value: 11},
            { label:this.$t('DECEMBER'), value: 12},
        ]
    }

    private generateDayOfweekSschedule(): any[] {
        return [
            { label: "*", value: "*"},
            { label:this.$t('SUNDAY'), value: 0},
            { label:this.$t('MONDAY'), value: 1},
            { label:this.$t('TUESDAY'), value: 2},
            { label:this.$t('WEDNESDAY'), value: 3},
            { label:this.$t('THURSDAY'), value: 4},
            { label:this.$t('FRIDAY'), value: 5},
            { label:this.$t('SATURDAY'), value: 6}
        ]
    }

    public concatCron(){
        const cronStr = `${this.minSchedule} ${this.hourSchedule} ${this.dayOfMonthSchedule} ${this.monthSchedule} ${this.dayOfWeekSchedule}`
        return cronstrue.toString( cronStr, { locale: this.$i18n.locale.split('-')[0], use24HourTimeFormat: true })
    }

    async mounted() {
        this.monthScheduleOptions = this.generateMonthSchedule()
        this.dayOfWeekScheduleOptions = this.generateDayOfweekSschedule()

        this.generateAllRewardOptions()
        this.minuteScheduleOptions = this.generateSchedule(0, 59)
        this.hourScheduleOptions = this.generateSchedule(0, 23)
        this.dayOfMonthScheduleOptions = this.generateSchedule(1, 31)

        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        this.tournamentId = this.$route.query.id.toString()
        await this.getTournamentInfo(this.tournamentId)
        this.addRouteName()
        this.displayShow = true
        loader.hide()
    }

    @Watch('$i18n.locale')
    onPropertyChanged() {
        this.monthScheduleOptions = this.generateMonthSchedule()
        this.dayOfWeekScheduleOptions = this.generateDayOfweekSschedule()
    }

    async getTournamentInfo(tournamenId: string): Promise<void> {
        const reponse = await GET_TOURNAMENT_BY_ID(tournamenId)
        const tournamentInfo = reponse[0]
        this.tierName = tournamentInfo.title
        this.buyin = tournamentInfo.buyin
        this.startStacksize = this.formatNumber(tournamentInfo.startStacksize.toString())
        this.minimumOfPeople = tournamentInfo.minimumPlayer
        this.maximumOfPeople = tournamentInfo.maxSize
        this.startTime = moment.unix(tournamentInfo.startTime).toDate()
        this.allReward = tournamentInfo.prizePool.prizePoolSetting.winnerAllPT
        this.topRewards = tournamentInfo.prizePool.prizePoolSetting.winnerTop.map( x => ({rewardPT: x.rewardPT.toString()}))
        this.baseRewards = tournamentInfo.prizePool.prizePoolSetting.winnerBasePT.map( x => ({playerPT: x.playerPT.toString(), rewardPT: x.rewardPT.toString()}))
        const hasBlindLevelSetting = tournamentInfo.blindLevels.length > 0
        if (hasBlindLevelSetting) {
            this.activeAnte = tournamentInfo.blindLevels.some( blindLevel => blindLevel.ante > 0)
            this.blindLevels = tournamentInfo.blindLevels.map(( currLevel, index) => ({ ...currLevel, id: index + 1 }) )
        }
        else {
            this.blindLevels.push({ id: 1, smallBlind: '', bigBlind: '', ante: '', duration: '' })
        }


        [this.minSchedule, this.hourSchedule, this.dayOfMonthSchedule, this.monthSchedule, this.dayOfWeekSchedule] = this.splitSchedule(tournamentInfo.resetSchedule) 
        this.durationOfSchedule = tournamentInfo.duration
        this.registerLate = tournamentInfo.registerLate
        
    }

    private splitSchedule(resetSchedule: string): Array< string | number>{
        const schedules = resetSchedule.split(' ')
        return schedules.map( x => x === '*' ? x : parseInt(x))
    }

    public addNewTopReward(){
        this.topRewards.push({rewardPT: '0'})
    }

    public deleteTopReward(index: number): void{
        this.topRewards.splice(index, 1);
    }

    public addNewBaseReward(){
        this.baseRewards.push({ playerPT: '0', rewardPT: '0' })
    }

    public deleteBaseReward(index: number): void{
        this.baseRewards.splice(index, 1);
    }

    public onlyNumber(event: any) {
        const keyCode = (event.keyCode ? event.keyCode : event.which);
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
            event.preventDefault();
        }
    }

    public formatNumber(value) {
        const numberCredit = parseFloat(value.replaceAll(",",""))
        return numberCredit.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    private addRouteName(): void {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }


    public setAnte(active: "YES" | "NO"): void {
        this.activeAnte = active === "YES"
    }

    public addNewBindLevel(): void {
        const hasIndex = this.blindLevels.length > 0
        let lastId = 0
        if (hasIndex){
            lastId = this.blindLevels[this.blindLevels.length - 1].id
        }
        this.blindLevels.push({ id: lastId + 1, smallBlind: '', bigBlind: '', ante: '', duration: '' })
    }

    public deleteBindLevel(index: number): void {
        this.blindLevels.splice(index, 1);
    }

    public sendForm(event: any): void {
        event.preventDefault();
        this.sendEditTournament()
    }

    isBlindLevelPass(): boolean {
        let isPass = true
        for(let i = 0; i < this.blindLevels.length; i++){

            const isSmallBlindPass = parseInt(this.blindLevels[i].smallBlind.toString()) >= 1
            const isBigBlindPass = parseInt(this.blindLevels[i].bigBlind.toString()) >= 1
            const isAntePass = (!this.activeAnte) || parseInt(this.blindLevels[i].ante.toString()) >= 0
            const isDurationPass = parseInt(this.blindLevels[i].duration.toString())  >= 2
            
            if(!isSmallBlindPass){
                this.alertValidator(this.$t('TOURNAMENT.ALERT_BLIND_LEVEL_SB').toString())
                isPass = false
                break
            } 

            if(!isBigBlindPass){
                this.alertValidator(this.$t('TOURNAMENT.ALERT_BLIND_LEVEL_BB').toString())
                isPass = false
                break
            } 

            if(!isAntePass){
                this.alertValidator(this.$t('TOURNAMENT.ALERT_BLIND_LEVEL_ANTE').toString())
                isPass = false
                break
            } 

            if(!isDurationPass){
                this.alertValidator(this.$t('TOURNAMENT.ALERT_BLIND_LEVEL_DURATION').toString())
                isPass = false
                break
            }
        }
        
        return isPass
    }

    alertValidator(message){
        this.$swal({
            text: message,
            icon: "error",
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
        });
    }

    public async sendEditTournament() {

        const startStacksize = parseInt(this.startStacksize.replace(/,/g, ''))
        const isStartStacksizePass = startStacksize >= 0
        if(!isStartStacksizePass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_START_STACKSIZE').toString())
            return
        }

        const isMinimumPass = parseInt(this.minimumOfPeople.toString()) >= 5
        if(!isMinimumPass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_MINIMUM_PEOPLE').toString())
            return
        }

        const isMaximumPass = parseInt(this.maximumOfPeople.toString()) >= 5 && parseInt(this.maximumOfPeople.toString()) >= parseInt(this.minimumOfPeople.toString())
        if(!isMaximumPass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_MAXIMUM_PEOPLE').toString())
            return
        }

        // const isStartTimeAndEndTimePass = this.start_time < this.end_time
        // if(!isStartTimeAndEndTimePass){
        //     this.alertValidator(this.$t('TOURNAMENT.ALERT_START_TIME').toString())
        //     return
        // }

        const isMinutesPass = this.minSchedule === '*' || parseInt(this.minSchedule.toString()) >= 0 && parseInt(this.minSchedule.toString()) <= 59
        if(!isMinutesPass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_MINUTES_SCHEDULE').toString())
            return
        }
        
        const isHoursPass = this.hourSchedule === '*' || parseInt(this.hourSchedule.toString()) >= 0 && parseInt(this.hourSchedule.toString()) <= 23
        if(!isHoursPass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_HOURS_SCHEDULE').toString())
            return
        }

        const isDayOfMonthPass = this.dayOfMonthSchedule === '*' || parseInt(this.dayOfMonthSchedule.toString()) >= 1 && parseInt(this.dayOfMonthSchedule.toString()) <= 31
        if(!isDayOfMonthPass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_DAY_OF_MONTH_SCHEDULE').toString())
            return
        }

        const isMonthPass = this.monthSchedule === '*' || parseInt(this.monthSchedule.toString()) >= 1 && parseInt(this.monthSchedule.toString()) <= 12
        if(!isMonthPass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_MONTH_SCHEDULE').toString())
            return
        }

        const dayOfWeekPass = this.dayOfWeekSchedule === '*' || parseInt(this.dayOfWeekSchedule.toString()) >= 0 && parseInt(this.dayOfWeekSchedule.toString()) <= 6
        if(!dayOfWeekPass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_DAY_OF_WEEK_SCHEDULE').toString())
            return
        }


        const isAllRewardsPass = this.allReward >= 1 && this.allReward <= 100
        if(!isAllRewardsPass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_ALL_REWARDS').toString())
            return
        }

        const sumPercentageReward = this.calculateSumPercentageReward(this.topRewards) + this.calculateSumPercentageReward(this.baseRewards)
        const isPercentageRewardPass = sumPercentageReward === 100
        if(!isPercentageRewardPass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_SUM_TOP_REWARD_AND_SUM_BASE_REWARD').toString())
            return
        }

        const sumPercentagePlayerCanWinReward = this.baseRewards.reduce((partialSum, reward: any) => partialSum + parseInt(reward.playerPT), 0)
        const isPercentagePlayerCanWinRewardPass = sumPercentagePlayerCanWinReward === 100
        if(!isPercentagePlayerCanWinRewardPass){
            this.alertValidator(this.$t('TOURNAMENT.ALERT_SUM_NUMBER_OF_PPL_WHO_RECIEVE_A_PRICE').toString())
            return
        }

        if (!this.isBlindLevelPass()) {
            return;
        }

        const blindLevels = this.getNormalizeBlindLevel()

        const confirmationResult = await this.$swal({
            title: this.$t('TOURNAMENT.WANT_ADD_DATA').toString(),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        })

        if (!confirmationResult.isConfirmed) {
            return
        }

        const payload = {
            tournamentId: this.tournamentId,
            buyin: this.buyin,
            startStacksize,
            allReward: this.allReward,
            topRewards: this.topRewards.map( x => ({rewardPT: parseInt(x.rewardPT)})),
            baseRewards: this.baseRewards.map( x => ({playerPT: parseInt(x.playerPT), rewardPT: parseInt(x.rewardPT)})),
            'minimum_of_people': parseInt(this.minimumOfPeople.toString()),
            'maximum_of_people': parseInt(this.maximumOfPeople.toString()),
            activeAnte: this.activeAnte,
            blindLevels
        }

        
        const resultCreateTournament = await UPDATE_TOURNAMENT(payload)
        if (!resultCreateTournament.success) {
            this.$swal({
                text: resultCreateTournament.error.message,
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return
        }

        this.$swal({
            text: this.$t('TOURNAMENT.EDIT_TOURNAMENT_SUCCESS').toString(),
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
            timerProgressBar: true,
            onClose: () => {
                window.location.replace('/tournament/list');
            }
        });

    }

    calculateSumPercentageReward(rewards: any[]): number {
        return rewards.reduce((partialSum, reward) => partialSum + parseInt(reward.rewardPT), 0);
    }

    getNormalizeBlindLevel(){
        return this.blindLevels.map( ({ smallBlind, bigBlind, ante, duration }) => { 
            const vSmall = parseInt(`${smallBlind}`.replace(/,/g, ''))
            const vBig = parseInt(`${bigBlind}`.replace(/,/g, ''))
            const vAnte = parseInt(`${ante}`.replace(/,/g, ''))
            const vDuration = parseInt(duration)
            return { 
                smallblind: vSmall, 
                bigblind: vBig, 
                ante: vAnte, 
                duration: vDuration
            }
        })
    }

}
export default Agent