


































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import AddTournamentScriptComponent from "./script.component";

@Component
export default class AddTournamentViewComponent extends Mixins(AddTournamentScriptComponent) { }

